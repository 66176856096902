import {React, useState, useEffect} from 'react';
import  useLogin  from '../../hooks/useLogin';
import { Input, Row, Col, Card, Layout } from 'antd';
import './Login.css';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

import {COLORS} from "../../const/styles.js";
import Button from "../../components/Button/Button";
import AppHeader from "../../components/app-header/AppHeader";
import {REDIRECT_URI_PARAM} from "./const/index.js";

const { Header, Content, Footer, Sider } = Layout;
const HOME_ROUTE = '/';


const headerStyle = {
  // textAlign: 'center',
  // align: 'center',
  color: '#fff',
  // lineHeight: '0.2em',
  backgroundColor: '#7dbcea',
  // paddingRight: '10px',
};

// function Login(props) {
//   const { user, isLoading, handleMicrosoftLogin, handleGoogleLogin, handleSignOut } = useLogin();
//   if (!user && isLoading) {
//     return <div>Loading...</div>;
//   }
//   if (!user) {
//     return (
//       <Layout style={{background: COLORS.bgLoginGradient}}>
//         <AppHeader isAuthorized={false}></AppHeader>
//         <Content className={'login-content'}>
//           <Row justify="center" align="middle" className="row-container">
//             {/*<Col xs={32} sm={32} md={32} lg={32} xl={32}>*/}
//             <Col span={24} md={16} lg={16} className={'card-wrap'}>
//               <Card className="card-container">
//                 <div className="sign-in-container">
//                   <p>Please sign in to continue</p>
//                   <div className={'login-or-container'}>OR</div>
//                   <div className={'social-buttons-wrap'}>
//                     <Button onClick={handleGoogleLogin}
//                             text={'Sign in with Google'}
//                             iconSrc={'/images/icons/google.svg'}
//                     />
//                     <Button onClick={handleMicrosoftLogin}
//                             text={'Sign in with Microsoft'}
//                             iconSrc={'/images/icons/msft.svg'}
//                     />
//                   </div>
//                 </div>
//               </Card>
//             </Col>
//           </Row>
//         </Content>
//       </Layout>
//     );
//   }
//   if (user) {
//     return props.children;
//   }
// }


function LoginNoHeader(props) {
    const { user, isLoading, handleMicrosoftLogin, handleGoogleLogin, handleEmailLinkLogin,  handleSignOut } = useLogin();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
  
    const handleEmailLogin = async (email) => {
        if (email) {
          setButtonDisabled(true);
          handleEmailLinkLogin(email);
          setEmailSent(true);
        }
      };    

    // Add a useEffect to enable the button after a delay, or when the email is changed
    useEffect(() => {
    if (isButtonDisabled) {
        const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
        setEmailSent(false); // Remove the message after the timeout
        }, 6000); // This will re-enable the button after 10 seconds

        return () => clearTimeout(timeoutId); // Clear timeout if the component is unmounted
    }
    }, [isButtonDisabled, email]);

    useEffect(() => {
        if (user) {
            const redirectURI = searchParams.get('redirect_uri');
            navigate(redirectURI ? decodeURIComponent(redirectURI) : '/', { replace: true });
        }
    }, [user, navigate, searchParams]);

    if (user) {
        return props.children;
    }

    if (!user && isLoading) {
        return <div>Loading...</div>;
    }

    const loginBox = (
        <Row justify="center" align="middle" className="row-container">
            <Col span={24} md={16} lg={16} className={'card-wrap'}>
                <Card className="card-container">
                    <div className="sign-in-container">
                        <p>Please sign in to continue</p>
                        <div className={'login-email-container'}>
                            <Input
                                size="large"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email address"
                            />
                            <Button 
                                disabled={isButtonDisabled} 
                                onClick={() => handleEmailLogin(email)}
                                style={ isButtonDisabled ? { backgroundColor: '#d9d9d9', color: '#a0a0a0', cursor: 'not-allowed' } : {}}
                            >
                                Sign in with email link {isButtonDisabled}
                            </Button>
                            {emailSent &&  <p style={{ fontWeight: 'bold', color: 'blue' }}> Check your email for the sign-in link!</p>}
                        </div>
                        <div className={'login-or-container'}>OR</div>
                        <div className={'social-buttons-wrap'}>
                            <Button 
                                onClick={handleGoogleLogin} 
                                text={'Sign in with Google'}
                                iconSrc={'/images/icons/google.svg'}
                            />
                            {/* <Button 
                                onClick={handleMicrosoftLogin} 
                                text={'Sign in with Microsoft'}
                                iconSrc={'/images/icons/msft.svg'}
                            /> */}
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    );

    if (!user) {
        return props.blurContent ? (
            <div>
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    {loginBox}
                </div>
                <div className="blur-background">
                    {props.children}
                </div>
            </div>
        ) : (
            {loginBox}
        );
    }
};


function Login(props) {
    return (
      <Layout style={{ background: COLORS.bgLoginGradient }}>
        <AppHeader isAuthorized={false}></AppHeader>
        <Content className={'login-content'}>
          <LoginNoHeader onLoginSuccess={props.onLoginSuccess}>
            {props.children}
          </LoginNoHeader>
        </Content>
      </Layout>
    );
  }
  
  export default LoginNoHeader;

