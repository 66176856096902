// smartlook.js
export const initializeSmartlook = () => {
    if (!window.smartlook) {
      (function(d) {
        var o = function() {
          o.api.push(arguments)
        }, h = d.getElementsByTagName('head')[0];
        var c = d.createElement('script'); o.api = []; c.async = true; c.type = 'text/javascript';
        c.charset = 'utf-8'; c.src = 'https://web-sdk.smartlook.com/recorder.js'; h.appendChild(c);
        window.smartlook = o;
      })(document);
      window.smartlook('init', 'ab3aae867ee2d747b5a2d66d271aa9a58faa0186', { region: 'eu' });
    }
  };
  