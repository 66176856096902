import React, { useState, useEffect, useRef } from 'react';
import { db } from '../modules/login/firebase'; // Import your Firebase config
import { collection, addDoc, onSnapshot, query, orderBy, doc, getDoc } from 'firebase/firestore';
import BaseLayout from './BaseLayout';
import LoginNoHeader from '../modules/login/Login'; // Ensure the path is correct
import  useLogin  from '../hooks/useLogin';
import {sendMessageAPI, sendAssistantMessageNewThread} from '../requests/messages'
import {auth} from '../modules/login/firebase'
import { getAuth, signInAnonymously } from "firebase/auth";

const ChatPage = ({ user, handleSignOut }) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const chatEndRef = useRef(null); // Ref for auto-scrolling
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Step 1
  const [showFooter, setShowFooter] = useState(false); // State for footer visibility
  const [welcomeMessageSent, setWelcomeMessageSent] = useState(false); // Step 1: New state variable
  const [threadId, setThreadId] = useState('');

  // useEffect(() => {
  //     if (user) {
  //         setMessages([
  //             { id: 1, text: `Hello ${user.displayName || user.email}, how can I assist you today?`, sender: 'assistant', timestamp: new Date() },
  //             { id: 2, text: 'I need help with meditation techniques.', sender: 'user', timestamp: new Date() },
  //             { id: 3, text: 'Sure, I can help you with that. What type of meditation are you interested in?', sender: 'assistant', timestamp: new Date() }
  //         ]);
  //     } else {
  //         setMessages([
  //             { id: 1, text: 'Hello Guest, how can I assist you today?', sender: 'assistant', timestamp: new Date() },
  //             { id: 2, text: 'I need help with meditation techniques.', sender: 'user', timestamp: new Date() },
  //             { id: 3, text: 'Sure, I can help you with that. What type of meditation are you interested in?', sender: 'assistant', timestamp: new Date() }
  //         ]);
  //     }
  // }, [user]);

  // Function to set the welcome message
  function setWelcomeMessageAndCallEndpoint() {
    if (welcomeMessageSent) return; // Step 2: Check if the message has already been sent
    const welcomeMessage = user && user.displayName ? 
      `Hello ${user.displayName} and thanks for signing up to Meditate Studio. This space is entirely safe and private, thoughtfully designed for your comfort and well-being.\n\nAs a special offer for first-time users, enjoy a complimentary session on us. Here are the available options:\n\n- Basic guided meditation\n- Sleep meditation\n- Breath meditation\n\nWhich one would you like to try first? 🌱🧘‍♂️✨` :
      `Hi, welcome to Meditate Studio! This space is entirely safe and private, thoughtfully designed for your comfort and well-being. May I ask what is your first name?`;

      setMessages([
      {
        id: 1,
        text: welcomeMessage,
        sender: 'assistant',
        timestamp: new Date()
      }      
    ]);
    sendAssistantMessageNewThread(welcomeMessage).then((response) => {
      setRefreshTrigger(prev => !prev); // Step 2: Toggle the trigger to re-invoke useEffect
      setWelcomeMessageSent(true); // Step 3: Update the state to indicate the message has been sent
      const threadId = response.thread_id; // Capture thread_id from the response
      setThreadId(threadId); // Update threadId in centralized state
    }).catch((error) => {
      console.error("Error sending welcome message:", error);
    });
  }

  useEffect(() => {
    // Assuming 'user' contains the current user's information
    // and 'uid' is the field in the user document that stores the user's ID
    if (user && user.uid) {
      const userRef = doc(db, 'users', user.uid);
      getDoc(userRef).then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const threadId = docSnapshot.data().thread_id; // Assuming 'thread_id' is the field
          setThreadId(threadId); // Update threadId in centralized state
        } else {
          console.log("No such user document!");
            // User document does not exist, set welcome message
            setWelcomeMessageAndCallEndpoint();
        }
      });
    } else {
        console.log("new user?");
        signInAnonymously(auth)
        .then(() => {
          // Signed in anonymously
          console.log("User signed in anonymously");
          // User document does not exist, set welcome message
          setWelcomeMessageAndCallEndpoint();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Anonymous sign-in failed: ${errorCode}, ${errorMessage}`);
        });
    }
  }, [user, refreshTrigger]); // Dependency array includes user.uid to re-run useEffect if the user changes
  
  useEffect(() => {
    // Subscribe to thread changes based on threadId
    if (threadId) {
      const threadRef = doc(db, 'threads', threadId);
      const unsubscribe = onSnapshot(threadRef, (doc) => {
        if (doc.exists()) {
          const threadData = doc.data();
          setMessages(threadData.messages); // Update messages in centralized state
        } else {
          console.log("No such thread document!");
          // Handle case where thread document does not exist
        }
      });
      return () => unsubscribe(); // Cleanup on unmount or threadId change
    }
  }, [threadId, setMessages]); // Dependency on threadId and setMessages

  useEffect(() => {
    // Auto-scroll to the latest message on initial load
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []); // Empty dependency array to run this effect only once on initial load

  useEffect(() => {
    // Auto-scroll to the latest message on messages update
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]); // Dependency array includes messages to trigger scroll on update

  useEffect(() => {
    // Auto-scroll to the latest message on initial load
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [input]); // Empty dependency array to run this effect only once on initial load


  // useEffect(() => {
  //   const messagesRef = collection(db, 'messages');
  //   const q = query(messagesRef, orderBy('timestamp'));
  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     setMessages(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  //   });
  //   return unsubscribe; // Clean up on unmount
  // }, []);

  const logoUrl = '/images/meditate-studio-logo-square-no-bg.png'; // Logo URL

  // const sendMessage = async (e) => {
  //   e.preventDefault(); // Prevent the form from refreshing the page
  //   const newMessage = {
  //     id: messages.length + 1,
  //     text: input,
  //     sender: 'user',
  //     timestamp: new Date().toISOString(),
  //   };
  //   setMessages([...messages, newMessage]);
  //   setInput(''); // Clear input after sending
  // };
  // Handle scroll event to show/hide the footer
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 80) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const sendMessage = async (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    
    // Assuming input is the message text
    if (input.trim()) { // Check if input is not just whitespace
      try {
        const response = await sendMessageAPI(input);
        setInput(''); // Clear input after messages are loaded
      } catch (error) {
        console.error("Failed to send message:", error);
        // Handle the error (e.g., show an error message to the user)
      }
    }
  };

  // const formatTimestamp = (timestamp) => {
  //   console.log(timestamp);
  //   const date = new Date(timestamp);
  //   const now = new Date();
  //   const diff = now - date;
  //   const oneDay = 24 * 60 * 60 * 1000;

  //   if (diff > oneDay) {
  //     return date.toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' });
  //   } else {
  //     return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
  //   }
  // };

  const formatTimestamp = (timestamp) => {
    // Convert Timestamp to milliseconds
    const milliseconds = (timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000);
    const date = new Date(milliseconds);

    // Get the user's local time
    const now = new Date();
    const diff = now - date;
    const oneDay = 24 * 60 * 60 * 1000;

    if (diff > oneDay) {
        return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    } else {
        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
    }
};

const formatMessageText = (text) => {
  if (text.includes('Basic guided meditation')) {
    // Split the text into lines and filter out lines starting with '-'
    const lines = text.split('\n');
    const listItems = lines.filter(line => line.startsWith('-')).map(line => `<li>• ${line.substring(1).trim()}</li>`).join('');
    // Remove lines starting with '-' from the original text
    const updatedText = lines.filter(line => !line.startsWith('-')).join('\n');
    // Add the formatted list items
    return `${updatedText}\n<ul>${listItems}</ul>`;
  }
  return text;
};

const linkify = (text) => {
  // Define the incorrect and correct URL patterns
  const incorrectUrlPattern = /https:\/\/api\.meditate\.studio\/player\/(.*\/with_music\.mp3)/g;
  const correctUrlBase = 'https://media.meditate.studio/';

  // Replace the incorrect URLs with the correct ones
  text = text.replace(incorrectUrlPattern, (match, p1) => {
    return `${correctUrlBase}${p1}`;
  });

  const markdownLinkRegex = /\[([^\]]+)\]\((http[s]?:\/\/[^\)]+)\)(\.)?/g;
  text = text.replace(markdownLinkRegex, (match, linkText, url) => {
    if (url.endsWith('.mp3')) {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700">${linkText}</a><div class="audio-message mt-4"><audio controls class="w-full"><source src="${url}" type="audio/mpeg"></audio></div><br>`;
    } else {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700">${linkText}</a>`;
    }
  });

  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  text = text.replace(urlRegex, (url) => {
    // Avoid replacing URLs that are already part of an anchor or audio tag from the previous step
    if (!text.includes(`href="${url}"`) && !text.includes(`src="${url}"`)) {
      if (url.endsWith('.mp3')) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700">${url}</a><div class="audio-message mt-4"><audio controls class="w-full"><source src="${url}" type="audio/mpeg"></audio></div><br>`;
      } else {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700">${url}</a>`;
      }
    }
    return url; // Return the original URL if it's part of a Markdown link
  });

  return text;
};


  const Footer = () => (
    <footer className="w-full bg-white py-4 mt-auto pt-8 mt-4">
    <div className="max-w-4xl mx-auto flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 px-4">
      <div className="text-xs">
        <h3 className="font-semibold text-sm mb-2">LEGAL</h3>
        <a href="https://www.meditate.studio/privacy-policy" className="text-blue-500 hover:underline block">Privacy Policy</a>
        <a href="https://www.meditate.studio/copy-of-privacy-policy" className="text-blue-500 hover:underline block">Terms of Service</a>
      </div>
      <div className="text-xs max-w-xs flex-grow md:ml-8 md:text-right">
        <h3 className="font-semibold text-sm mb-2">SUBSCRIBE TO OUR NEWSLETTER</h3>
        <p className="text-gray-600 mb-2">Stay in the loop with the latest news and updates from Meditate Studio by joining our waiting list.</p>
        <div className="flex justify-end">
          <input
            type="email"
            placeholder="Your Email Address"
            className="flex-grow p-2 border rounded-l-lg focus:outline-none focus:ring focus:border-blue-300 md:flex-grow-0 md:w-64"
          />
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-lg">
            Subscribe
          </button>
        </div>
      </div>
    </div>
    <div className="text-center text-gray-600 text-xs mt-2">
      <a href='https://www.meditate.studio'>© 2024 Meditate Studio. All Rights Reserved.</a>
    </div>
  </footer>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="flex-shrink-0">
        <div className="top-bar">
        </div>
      </header>
      <main className="flex-grow">
      <div className="flex flex-col items-center justify-between h-screen p-6 bg-gray-100">
        <div className="flex justify-between items-center w-full mb-4">
          <a href="https://www.meditate.studio" target="_blank" rel="noopener noreferrer" className='text-blue-500 hover:text-blue-700 py-2 px-4 rounded-lg'>Home</a>
          {user ? (
            <div className="text-left">
              {user.displayName || user.email}
              <a href="#" onClick={handleSignOut} className="text-blue-500 hover:text-blue-700 py-2 px-4 rounded-lg">Logout</a>
            </div>
          ) : (
            <div>Not logged in</div>
          )}
      </div>
        <div className="w-full max-w-4xl p-4 bg-white rounded-lg shadow-lg flex flex-col justify-between h-full">
          <div className="flex items-center justify-center mb-4">
            <img src={logoUrl} alt="Meditate Studio Logo" className="w-16" />
            <h1 className="text-2xl font-bold mx-4 text-center">Welcome to Meditate Studio</h1>
          </div>
          <div className="chat-container flex flex-col space-y-4 p-4 overflow-y-auto flex-grow no-scrollbar">
            {messages && messages.map((message) => (
              <div
                key={message.timestamp}
                className={`message p-3 rounded-lg shadow max-w-lg flex items-start ${
                  message.sender === 'user' ? 'bg-blue-500 text-white self-end' : 'bg-gray-300 self-start'
                }`}
              >
                {message.sender === 'assistant' && (
                  <img src={logoUrl} alt="Meditate Studio Logo" className="w-8 h-8 mr-2 object-contain" />
                )}
                <div>
                  {message.sender === 'user' && <div className="text-xs text-white">{user.displayName || user.email}</div>}
                  <div dangerouslySetInnerHTML={{ __html: linkify(formatMessageText(message.text))}}></div>
                  <div className="text-xs mt-1 text-gray-700 text-right">
                    {formatTimestamp(message.timestamp)}
                  </div>
                </div>
              </div>
            ))}
            <div ref={chatEndRef}></div> {/* Scroll target element */}
          </div>          
          <form onSubmit={sendMessage} className="message-input flex mt-4 mb-8">
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type a message..."
              className="flex-grow p-3 border rounded-l-lg focus:outline-none focus:ring focus:border-blue-300"
            />
            <button
              type="submit"
              disabled={!input.trim()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-r-lg disabled:opacity-50 flex-shrink-0"
            >
                Send
            </button>
          </form>
        </div>
      </div>
      <div className="mt-20">
        {/* add some space here before footer */}
      </div>
      </main>
    {/* <Footer/> */}
    </div>
  )
};


const ChatPageWithLogin = () => {
  const { user, isLoading, handleSignOut } = useLogin();

  useEffect(() => {
    if (!user && !isLoading) {
      signInAnonymously(auth)
        .then(() => {
          // Signed in anonymously
          console.log("User signed in anonymously");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Anonymous sign-in failed: ${errorCode}, ${errorMessage}`);
        });
    }
  }, [user, isLoading, auth]);

  return (
    // <div className="relative flex flex-col min-h-screen bg-blue-100">
    <div>
        {/* <LoginNoHeader blurContent={true}> */}
          <ChatPage user={user} handleSignOut={handleSignOut}/>
        {/* </LoginNoHeader> */}
    </div>
  );
};

export { ChatPage, ChatPageWithLogin };
