import axios from "axios";
import http from "../utils/http.js";
import { FASTAPI_URL} from "../const/api";
import {auth} from '../modules/login/firebase'


export async function sendMessageAPI(message) {
  let token = await auth.currentUser.getIdToken(auth);

  return http.post(`${FASTAPI_URL}/webapp-incoming/`, {
        prompt: message
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
        allowRetry: true,
        // queueName: 'TEXT_GENERATION'
    });
}

export async function sendAssistantMessageNewThread(message) {
  let token = await auth.currentUser.getIdToken(auth);

  return http.post(`${FASTAPI_URL}/webapp-new-thread/`, {
        prompt: message
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
        allowRetry: true,
        // queueName: 'TEXT_GENERATION'
    });
}
