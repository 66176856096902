// StepOne.js

import {useState} from "react";

export const StepOne = ({ onNext }) => {
  const languages = [
    { code: 'en', name: 'English 🇬🇧', country: "GB" }, // UK's ISO country code is GB
    { code: 'es', name: 'Español 🇪🇸', country: "ES" },
    { code: 'fr', name: 'Français 🇫🇷', country: "FR" },
    { code: 'de', name: 'Deutsch 🇩🇪', country: "DE" },
    { code: 'it', name: 'Italiano 🇮🇹', country: "IT" },
    { code: 'pt', name: 'Português 🇵🇹', country: "PT" },
    { code: 'ru', name: 'Русский 🇷🇺', country: "RU" },
    { code: 'zh', name: '中文 🇨🇳', country: "CN" },
    { code: 'ja', name: '日本語 🇯🇵', country: "JP" },
    { code: 'ko', name: '한국어 🇰🇷', country: "KR" },
    { code: 'ar', name: 'العربية 🇸🇦', country: "SA" },
    { code: 'hi', name: 'हिन्दी 🇮🇳', country: "IN" },
    { code: 'bn', name: 'বাংলা 🇧🇩', country: "BD" },
    { code: 'tr', name: 'Türkçe 🇹🇷', country: "TR" },
    { code: 'vi', name: 'Tiếng Việt 🇻🇳', country: "VN" },
    { code: 'pl', name: 'Polski 🇵🇱', country: "PL" },
    { code: 'nl', name: 'Nederlands 🇳🇱', country: "NL" },
    { code: 'sv', name: 'Svenska 🇸🇪', country: "SE" }
  ];
  
  const [selectedLang, setSelectedLang] = useState(null);
  
  const handleLanguageSelect = (langCode) => {
    setSelectedLang(langCode);
    onNext(); // Assuming this function navigates to the next screen
  };

  return (
  <div className="flex flex-col h-screen">
      <div className="p-4 top-0 z-10">
        <p>Select your meditation langauge</p>
        {/* Include your questions and options here */}
      </div>
  <div className="flex-grow overflow-y-auto mb-6" style={{
     /* Hide scrollbar for Chrome, Safari and Opera */
  '::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge, and Firefox */
  '-ms-overflow-style': 'none', /* IE and Edge */
  'scrollbar-width': 'none', /* Firefox */
  }}>
    <div className="scrollable-list">
        {
        languages.map((lang) => (
          <button 
            onClick={() => handleLanguageSelect(lang.code)} 
            className={`flex items-center justify-center p-2 m-2 rounded-lg shadow w-full ${
              selectedLang === lang.code ? 'bg-blue-500 text-white' : 'bg-white'
            }`}
          >
            {/* <FontAwesomeIcon icon={faBrain} className="text-blue-500 text-3xl mr-2" /> */}
            <img className="m-2" src={`https://flagsapi.com/${lang.country.toUpperCase()}/flat/16.png`} alt={`${lang.country} Flag`} />
            <span id={lang.code} className="text-gray-700 font-semibold text-lg"> {lang.name}</span>
          </button>
        ))
        }
    </div>
  </div>
  </div>
) ;

}