// StepTwo.js

import {useState} from "react";

export const StepTwo = ({ onNext }) => {
  const times = [
    { id: 1, label: 'Early Morning (5am - 8am)' },
    { id: 2, label: 'Morning (8am - 12pm)' },
    { id: 3, label: 'Afternoon (12pm - 4pm)' },
    { id: 4, label: 'Evening (4pm - 8pm)' },
    { id: 5, label: 'Night (8pm - 12am)' }
  ];
  
  const [selectedTime, setSelectedTime] = useState(null);
  
  const handleTimeSelect = (timeId) => {
    setSelectedTime(timeId);
    onNext(); // Assuming this function navigates to the next screen
  };

  return (
  <div className="flex flex-col h-screen">
      <div className="p-4 top-0 z-10">
        <p>When do you want to get your daily mindfulness session?</p>
        {/* Include your questions and options here */}
      </div>
  <div className="flex-grow overflow-y-auto mb-6" style={{
     /* Hide scrollbar for Chrome, Safari and Opera */
  '::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge, and Firefox */
  '-ms-overflow-style': 'none', /* IE and Edge */
  'scrollbar-width': 'none', /* Firefox */
  }}>
    <div className="scrollable-list">
        {
        times.map((time) => (
          <button 
            onClick={() => handleTimeSelect(time.id)} 
            className={`flex items-center justify-center p-2 m-2 rounded-lg shadow w-full ${
              selectedTime === time.id ? 'bg-blue-500 text-white' : 'bg-white'
            }`}
          >
            <span id={`time-${time.id}`} className="text-gray-700 font-semibold text-lg"> {time.label}</span>
          </button>
        ))
        }
    </div>
  </div>
  </div>
) ;

}