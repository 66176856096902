// BaseLayout.js
import React from 'react';

const BaseLayout = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-100 p-4 relative">
      {children}
    <div className="mb-32"></div> {/* This adds more space at the bottom */}
    </div>
  );
};

export default BaseLayout;