
import React, { useState } from 'react';
import BaseLayout from './BaseLayout';
// import LanguageSelection from './LanguageSelection';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { Link } from 'react-router-dom';


const SignupWizardPage = () => {
  const [language, setLanguage] = useState('en'); // Default language is English
  const [currentStep, setCurrentStep] = useState(1); // State to track the current step

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    // Additional actions on language change if necessary
  };

  const moveToNextStep = () => {
    setCurrentStep(currentStep + 1); // Update the current step to 2
  };

  return (
    <BaseLayout>
      <div className="w-full max-w-xs mx-auto">
        <h2 className="text-xl font-bold mb-4">Sign Up</h2>
        {/* <LanguageSelection onLanguageChange={handleLanguageChange}>
          <div>bla</div>
        </LanguageSelection> */}
        {/* Form for signing up users */}
        {/* show currentStep */}
        {currentStep === 1 && <StepOne onNext={moveToNextStep} />}
        {currentStep === 2 && <StepTwo onNext={moveToNextStep} />}
        {currentStep === 3 && <StepThree onNext={moveToNextStep} />}
        <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg text-lg transition duration-300 ease-in-out">
              Back
        </Link>
      </div>
    </BaseLayout>
  );
};

export default SignupWizardPage;
