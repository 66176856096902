
// export default MainPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faDoorOpen, faBrain } from '@fortawesome/free-solid-svg-icons';
// SignUpWizard.js or the name of your wizard component
import BaseLayout from './BaseLayout';

const LandingPage = () => {
  return (
      <BaseLayout>
      <div className="flex items-center space-x-4 mb-6">
        {/* <img src="https://api.meditate.studio/images/meditate-studio-logo-square.png" alt="Meditate Studio Logo" className="w-16" /> */}
        <img src="/images/meditate-studio-logo-square-no-bg.png" alt="Meditate Studio Logo" className="w-16" />
        <h1 className="text-5xl font-bold text-gray-800">Meditate Studio</h1>
      </div>
      <div className="w-full max-w-md p-5 bg-gray-50 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Create your Personalized AI Meditate Buddy on WhatsApp</h1>
        <div className="flex flex-col space-x-4 mb-4">
          <div className="flex items-center justify-center p-4 bg-white rounded-lg shadow">
            <FontAwesomeIcon icon={faWhatsapp} className="text-green-500 text-3xl mr-2" />
            <span className="text-gray-700 font-semibold text-lg">Daily meditation guidance to your WhatsApp</span>
          </div>
        </div>
        <div className="flex flex-row space-x-4 space-x-4 mb-4">
          <div className="p-4 bg-white rounded-lg shadow">
          <FontAwesomeIcon icon={faDoorOpen} className="text-blue-500 text-3xl mr-2" />  
            <span className="text-gray-700 font-semibold text-lg">24/7</span>
          </div>
          <div className="flex items-center justify-center p-4 bg-white rounded-lg shadow">
            <FontAwesomeIcon icon={faBrain} className="text-blue-500 text-3xl mr-2" />
            <span className="text-gray-700 font-semibold text-lg">Fully customised for you</span>
          </div>
        </div>
      </div>
      {/* Separate Comments Section Below */}
      <div className="w-full max-w-md mt-4">
        <div className="space-y-2">
          <div className="p-4 bg-white rounded-lg shadow">"Great experience, highly recommend!"</div>
          <div className="p-4 bg-white rounded-lg shadow">"Very helpful for staying focused."</div>
          <div className="p-4 bg-white rounded-lg shadow">"Love the daily reminders and tips!"</div>
          <div className="p-4 bg-white rounded-lg shadow">"Great experience, highly recommend!"</div>
          <div className="p-4 bg-white rounded-lg shadow">"Very helpful for staying focused."</div>
          <div className="p-4 bg-white rounded-lg shadow">"Love the daily reminders and tips!"</div>
        </div>
      </div>
      <div className="max-w-md p-5 bg-gray-50 rounded-lg shadow-lg text-center fixed bottom-4 left-1/2 transform -translate-x-1/2">
        <Link to="/signup-wizard" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg text-lg transition duration-300 ease-in-out">
            Get started with Meditate Studio!
          </Link>
        <p className="text-sm mt-6">Already have an account?
          <div className="inline-block px-2 py-1 ml-1">
              <Link to="/login" className="text-blue-500 hover:underline">Login now</Link>
          </div>
        </p>
      </div>
      <div className="flex justify-center space-x-4 mt-8">
        <a href="https://www.instagram.com/yourusername" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://twitter.com/yourusername" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a href="mailto:your-email@example.com">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </a>
      </div>
          </BaseLayout>
  );
};

export default LandingPage;

