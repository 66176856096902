// StepThree.js

import {useState} from "react";

export const StepThree = ({ onNext }) => {
  const voices = [
    { id: 1, label: 'Voice 1 - Calm and Soothing' },
    { id: 2, label: 'Voice 2 - Deep and Inspiring' },
    { id: 3, label: 'Voice 3 - Gentle and Reassuring' },
    { id: 4, label: 'Voice 4 - Energetic and Motivating' }
  ];
  
  const [selectedVoice, setSelectedVoice] = useState(null);
  
  const handleVoiceSelect = (voiceId) => {
    setSelectedVoice(voiceId);
  };

  return (
  <div className="flex flex-col h-screen">
      <div className="p-4 top-0 z-10">
        <p>Choose your favorite voice for the meditation</p>
        {/* Include your questions and options here */}
      </div>
  <div className="flex-grow mb-6" style={{
     /* Hide scrollbar for Chrome, Safari and Opera */
  '::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge, and Firefox */
  '-ms-overflow-style': 'none', /* IE and Edge */
  'scrollbar-width': 'none', /* Firefox */
  }}>
    <div className="scrollable-list">
        {
        voices.map((voice) => (
            <div className="flex flex-col items-center justify-center m-6 border">
            <button  
                onClick={() => handleVoiceSelect(voice.id)} 
                    className={`flex flex-col items-center justify-center p-2 rounded-lg shadow mr-2 w-full ${
                    selectedVoice === voice.id ? 'bg-blue-500 text-white' : 'bg-white'
                }`}
            >
                <span id={`voice-${voice.id}`} className="text-gray-700 font-semibold text-medium"> {voice.label}</span>
                <div className="flex flex-row items-center w-full">
                    <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M10 20a10 10 0 100-20 10 10 0 000 20zM7 8a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0zm-4.5 4a4.5 4.5 0 019 0v1H7v-1z" clipRule="evenodd" />
                    </svg>
                    <audio controls src={voice.sampleUrl} className="m-2 w-full">
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </button>
            </div>
        ))
        }
        

       <button onClick={onNext} className="p-2 m-2 bg-blue-500 text-white rounded-lg shadow">
        Next
      </button>
    </div>
  </div>
  </div>
) ;

}