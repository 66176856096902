import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage.js';
import SignupWizardPage from './components/SignupWizardPage'; // Import the new component
import {ChatPageWithLogin, ChatPage} from './components/ChatPage.js'; // Assuming you have a ChatPage component
import {Login} from './modules/login';
import {initializeSmartlook} from './smartlook.js'
import './styles.css'; // Make sure this path matches your project structure

// Import other components

function App() {
  useEffect(() => {
    initializeSmartlook();
  }, []);

  return (
    <Router>
        <Routes>
          {/* Route for Chat that does not require login */}
          <Route path="/" element={<ChatPageWithLogin />} />
          <Route path="/chat-no" element={<ChatPage />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/signup-wizard" element={<SignupWizardPage />} />

          {/* Wrap other components that require login */}
          <Route
            path="/sign/"
            element={
              <Login>
                <Routes>
                  {/* Define other routes that require login here */}
                </Routes>
              </Login>
            }
          />
        </Routes>
      </Router>
  );
}


// function App() {
//   return (
//     <Router>
//         <Routes>
//           {/* Route for Chat that does not require login */}
//           <Route path="/chat" element={<ChatPage />} />
//           <Route path="/" element={<MainPage />} />
//         </Routes>
//       </Router>
//   );
// }

export default App;
