import { useEffect, useState, useRef } from 'react';
import {auth} from '../modules/login/firebase'
import { GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut, 
    onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink } from "firebase/auth";
import { useLocation } from 'react-router-dom';
import { ALLOWED_HOSTS, FASTAPI_URL} from "../const/api";


// Cookie utility functions
const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

const eraseCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
};

function useLogin() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    let location = useLocation();
    let params = new URLSearchParams(location.search);
    // let redirectUri = params.get('redirect_uri');
    let redirectUri = params.get('continueUrl');
    let state = params.get('state');
    const hasRunCheckEmailLink = useRef(false); // Track if checkEmailLink has run once

    useEffect(() => {
        const checkEmailLink = async () => {
            if (hasRunCheckEmailLink.current) return; // Skip if already run
            hasRunCheckEmailLink.current = true; // Mark as run

            try {
                if (isSignInWithEmailLink(auth, window.location.href)) {
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (!email) {
                        email = window.prompt('Please provide your email for confirmation');
                    }
                    if (email) {
                        try {
                            const result = await signInWithEmailLink(auth, email, window.location.href);
                            window.localStorage.removeItem('emailForSignIn');
                            setUser(result.user);
                        } catch (error) {
                            console.error("Error signing in with email link:", error);
                            if (error.code === 'auth/invalid-action-code') {
                                alert("The link is invalid or expired. Please try signing in again.");
                            } else {
                                alert("An error occurred while signing in. Please try again.");
                            }
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        const handleAuthStateChange = async (user) => {
            if (user) {
                setUser(user);
                try {
                    const idToken = await user.getIdToken();
                    if (redirectUri) {
                        const url = new URL(redirectUri);
                        const hostname = url.hostname;

                        url.searchParams.append('code', idToken);
                        url.searchParams.append('state', state);

                        // Log the ID token and URL for debugging
                        console.log("ID Token:", idToken);
                        console.log("URL:", url.toString());

                        // Check if the hostname is in the ALLOWED_HOSTS list or is a subdomain of an allowed host before redirecting.
                        if (ALLOWED_HOSTS.some(allowedHost => hostname === allowedHost || hostname.endsWith(`.${allowedHost}`))) {
                            window.location.href = url.toString();
                        } else {
                            console.warn("Hostname not allowed:", hostname);
                        }
                    }
                } catch (error) {
                    console.error("Error getting ID token:", error);
                }
            } else {
                setUser(null);
            }
            setIsLoading(false);
        };

        checkEmailLink();

        const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);

        return () => unsubscribe();
    }, [location, redirectUri, state]);

    const handleGoogleLogin = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider);
    };

    const handleEmailLinkLogin = (email) => {
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true
        };
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleMicrosoftLogin = () => {
        const provider = new OAuthProvider('microsoft.com');
        signInWithPopup(auth, provider);
    };

    const handleSignOut = () => {
        auth.signOut();
    };

    return { user, isLoading, handleGoogleLogin, handleMicrosoftLogin, handleEmailLinkLogin, handleSignOut };
};

export default useLogin;
